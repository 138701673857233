import Bottleneck from 'bottleneck'
import useState from '@/composables/useState'

const { connecting, reconnectAttempts } = useState()

const bottleneck = new Bottleneck({
  maxConcurrent: 1,
  minTime: 10000,
  highWater: 1,
  strategy: Bottleneck.strategy.OVERFLOW,
  reservoir: 1
})

bottleneck.on('queued', (info) => {
  const { options, reachedHWM } = info
  const status = bottleneck.jobStatus(options.id)
  if (status === 'QUEUED' && !reachedHWM) {
    connecting.value = true
    reconnectAttempts.value = 0
  }
})
bottleneck.on('failed', async (error, jobInfo) => {
  // Exponential backoff:
  // on every retry, we exponentially increase the time to wait.
  // (2 ** 1) * 100 = 200 ms
  // (2 ** 2) * 100 = 400 ms
  // (2 ** 3) * 100 = 800 ms
  let expBackoffDelayMs = 500 + Math.random() * 500 + 2 ** jobInfo.retryCount * 100
  if (expBackoffDelayMs > 30000) expBackoffDelayMs = 1000 // cap max backoff delay to 30seconds
  console.warn(
    `${jobInfo.options.id} #${
      jobInfo.retryCount
    } => connect failed: ${error.message}, retrying in ${(expBackoffDelayMs / 1000).toFixed(
      1
    )} seconds...`
  )
  return expBackoffDelayMs
})
bottleneck.on('retry', async (message, jobInfo) => {
  connecting.value = true
  reconnectAttempts.value = jobInfo.retryCount
})
bottleneck.on('idle', () => {
  connecting.value = false
})

const useBottleneck = () => bottleneck

export default useBottleneck
