<template>
  <span>{{ (percentage.number * 100).toFixed(0) + '%' }}</span>
</template>

<script lang="ts" setup>
import { unref, reactive, watch, toRefs } from 'vue'
import gsap from 'gsap'

const props = defineProps<{ progress: number }>()
const { progress } = toRefs(props)

const percentage = reactive({ number: 0 })

const updatePercentage = () =>
  gsap.to(percentage, { duration: 0.1, number: Number(unref(progress)) })

watch(progress, updatePercentage)
</script>
