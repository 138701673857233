import { ref, computed, unref, watch } from 'vue'
import { openDB, type DBSchema } from 'idb'
import Bottleneck from 'bottleneck'
import useAuth from '@/composables/useAuth'
import useAxios from '@/composables/useAxios'
import useState from '@/composables/useState'
import prettyBytes from '@/helpers/prettyBytes'
import useSentry from '@/composables/useSentry'
import type { IAssetRecord, ICachedAsset } from '@vontage/types/v2/asset'

export interface VontageDB extends DBSchema {
  asset: {
    key: string
    value: ICachedAsset
    indexes: { lastAccess: Date }
  }
}

const MAX_RETRIES = 3

const limiter = new Bottleneck({
  maxConcurrent: 1
})

limiter.on('failed', (error, jobInfo) => {
  const delay = 1000 + Math.pow(2, jobInfo.retryCount) + Math.random() * 1000
  if (jobInfo.retryCount < MAX_RETRIES - 1) return delay
})

const { getBearerToken } = useAuth()
const { playerId, getPlayerRegistration, registrationStatus } = useState()
const { updateSentryContext } = useSentry()
const axios = useAxios()

const getDb = async () => {
  const db = await openDB<VontageDB>('vontage-player', 3, {
    async upgrade(db) {
      console.warn('upgrading db...')
      if (db.objectStoreNames.contains('asset')) db.deleteObjectStore('asset')
      const store = db.createObjectStore('asset', { keyPath: 'urn' })
      store.createIndex('lastAccess', 'lastAccess')
    }
  })
  return db
}

const requestedAssetIndex = ref<Record<string, boolean>>({})
const assetDownloadProgressIndex = ref<{
  [assetId: string]: { loaded: number; total: number }
}>({})
const downloadProgress = ref(NaN)

const fetchAsset = async (urn: string): Promise<IAssetRecord> => {
  const registration = getPlayerRegistration()
  if (registration === null) throw new Error('player not  registered')
  const { tenantId } = registration
  const headers: Record<string, string> = {
    'x-player-id': unref(playerId),
    'x-tenant-id': tenantId,
    Authorization: `Bearer ${await getBearerToken()}`
  }
  const asset = await axios
    .get<IAssetRecord>(`/assets/${btoa(urn)}`, {
      headers
    })
    .then(({ data }) => data)
  return asset
}

const fetchAssetBinary = async (urn: string): Promise<ICachedAsset> => {
  const db = await getDb()
  let asset = (await db.get('asset', urn)) ?? null
  if (asset === null) {
    const registration = getPlayerRegistration()
    if (registration === null) throw new Error('player not  registered')
    const { tenantId } = registration
    requestedAssetIndex.value[urn] = true

    const headers: Record<string, string> = {
      'x-tenant-id': tenantId,
      'x-player-id': unref(playerId),
      Authorization: `Bearer ${await getBearerToken()}`
    }
    const { blob, etag } = await axios
      .get(`/assets/${btoa(urn)}/binary`, {
        headers,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded } = progressEvent
          const currentEntry = unref(assetDownloadProgressIndex)[urn]
          if (!currentEntry) return
          currentEntry.loaded = loaded
          assetDownloadProgressIndex.value = {
            ...unref(assetDownloadProgressIndex),
            [urn]: currentEntry
          }
        }
      })
      .then((response) => {
        const etag: string = response.headers?.etag ?? ''
        const blob = response.data
        return { blob, etag }
      })
    asset = {
      urn,
      etag,
      blob,
      lastAccess: new Date(),
      size: blob.size,
      type: blob.type
    }
    await db.put('asset', asset)
    await updateSentryContext()
  } else {
    await db.put('asset', { ...asset, lastAccess: new Date() })
  }

  return asset
}

const fetchAssets = limiter.wrap(async (urns: string[]): Promise<ICachedAsset[]> => {
  const db = await getDb()
  for (const urn of urns) {
    if (!(await db.getKey('asset', urn))) {
      const total = await (await fetchAsset(urn)).size
      unref(assetDownloadProgressIndex)[urn] = { total, loaded: 0 }
    }
  }
  const assets = await Promise.all(urns.map(fetchAssetBinary))
  assets
    .map((asset) => asset.urn)
    .forEach((assetId) => delete unref(assetDownloadProgressIndex)[assetId])
  return assets
})

const fetchIdleScreenAssets = async () => {
  const UNREGISTERED_ASSET_KEY = '/videos/blue_pattern.mp4'
  const REGISTERED_ASSET_KEY = '/videos/blue_abstract.mp4'
  const db = await getDb()
  let unregisteredAsset = (await db.get('asset', UNREGISTERED_ASSET_KEY)) ?? null
  let registeredAsset = (await db.get('asset', REGISTERED_ASSET_KEY)) ?? null

  if (unregisteredAsset === null) {
    const { blob, etag } = await fetch(UNREGISTERED_ASSET_KEY).then(async (response) => {
      const blob = await response.blob()
      const etag = response.headers.get('etag') ?? ''
      return { blob, etag }
    })
    unregisteredAsset = {
      urn: UNREGISTERED_ASSET_KEY,
      blob,
      lastAccess: new Date(),
      size: blob.size,
      type: blob.type,
      etag
    }
    await db.put('asset', unregisteredAsset)
  } else {
    await db.put('asset', { ...unregisteredAsset, lastAccess: new Date() })
  }
  if (registeredAsset == null) {
    const { blob, etag } = await fetch(REGISTERED_ASSET_KEY).then(async (response) => {
      const blob = await response.blob()
      const etag = response.headers.get('etag') ?? ''
      return { blob, etag }
    })
    registeredAsset = {
      urn: REGISTERED_ASSET_KEY,
      blob,
      lastAccess: new Date(),
      size: blob.size,
      type: blob.type,
      etag
    }
    await db.put('asset', registeredAsset)
  } else {
    await db.put('asset', { ...registeredAsset, lastAccess: new Date() })
  }
  return { unregisteredAsset, registeredAsset }
}

const fetchLocalAsset = async (assetPath: string) => {
  const localAssetPrefix = `VONTAGE:LOCALASSET:`
  const assetKey = `${localAssetPrefix}${assetPath}`
  const db = await getDb()
  let asset = (await db.get('asset', assetKey)) ?? null

  if (asset === null) {
    const { blob, etag } = await fetch(assetPath).then(async (response) => {
      const blob = await response.blob()
      const etag = response.headers.get('etag') ?? ''
      return { blob, etag }
    })
    asset = {
      urn: assetKey,
      blob,
      lastAccess: new Date(),
      size: blob.size,
      type: blob.type,
      etag
    }
    await db.put('asset', asset)
  } else {
    await db.put('asset', { ...asset, lastAccess: new Date() })
  }
  return asset
}

const getStorageEstimate = async () => {
  const storageEstimate = await navigator.storage.estimate()
  const { quota = 0, usage = 0 } = storageEstimate
  const available = quota - usage
  const estimate = {
    available: prettyBytes(available),
    used: prettyBytes(usage),
    quota: prettyBytes(quota)
  }
  return estimate
}

const deleteAllLocalAssets = async () => {
  const db = await getDb()
  await db.clear('asset')
  requestedAssetIndex.value = {}
  console.debug('deleted all local assets from indexed db')
  const estimate = await getStorageEstimate()
  console.debug('deleted assets', estimate)
}

const allAssetsAvailable = computed(() => Object.keys(unref(requestedAssetIndex)).length === 0)

watch(
  registrationStatus,
  async () => {
    const tenantId = getPlayerRegistration()?.tenantId ?? null
    if (tenantId === null) {
      await deleteAllLocalAssets()
    }
  },
  { immediate: true }
)

const useAssets = () => {
  return {
    fetchAssets,
    fetchAssetBinary,
    fetchIdleScreenAssets,
    fetchLocalAsset,
    deleteAllLocalAssets,
    getStorageEstimate,
    requestedAssetIndex: computed(() => unref(requestedAssetIndex)),
    allAssetsAvailable,
    assetDownloadProgressIndex: computed(() => unref(assetDownloadProgressIndex)),
    downloadProgress: computed({
      get: () => unref(downloadProgress),
      set: (value: number) => {
        downloadProgress.value = value
      }
    })
  }
}

export default useAssets
