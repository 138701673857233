<template>
  <div class="fixed inset-0 flex items-center justify-center">
    <div :style="containerStyle" ref="container" :class="containerClass ?? ''">
      <div
        v-for="[layoutSectionKey, facet] in Object.entries(frame.facets)"
        :key="layoutSectionKey"
        :style="style[layoutSectionKey]"
      >
        <slot :name="layoutSectionKey" :facet="facet">
          <div
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
            "
          >
            {{ layoutSectionKey }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, unref, toRefs, computed, onMounted, onBeforeUnmount } from 'vue'
import type { Frame } from '@vontage/types/generated/playlist_v2'
import type { Layout } from '@vontage/types/generated/playlist_v2'

export type TRotation = '0' | '90' | '180' | '270'
const props = defineProps<{
  frame: Frame
  rotation?: TRotation
  containerClass?: string
}>()
const { frame, rotation, containerClass } = toRefs(props)

const container = ref<null | HTMLDivElement>(null)
const parentBBox = ref<null | DOMRect>(null)

const getLayoutSectionStyleIndex = (layout: Layout): Record<string, string> => {
  const { width: maxWidth, height: maxHeight } = layout
  const sectionStyleIndex = Object.entries(layout.sections).reduce(
    (accumulator: { [sectionKey: string]: string }, [sectionKey, section]) => {
      const leftRef = section.x0
      const topRef = section.y0
      const leftAbs = maxWidth
      const topAbs = maxHeight
      const width = (section.width / maxWidth) * 100 + '%'
      const height = (section.height / maxHeight) * 100 + '%'
      const left = (leftRef / leftAbs) * 100 + '%'
      const top = (topRef / topAbs) * 100 + '%'
      const style = `position:absolute; top:${top}; left:${left}; width:${width}; height:${height};`
      accumulator[sectionKey] = style
      return accumulator
    },
    {}
  )
  return sectionStyleIndex
}

// common denominator
const gcd = (a: number, b: number): number => (a === 0 ? b : gcd(b % a, a))

const containerStyle = computed(() => {
  const bbox = unref(parentBBox)
  if (bbox === null) return ''
  const { width, height } = unref(frame).layout
  const _rotation = unref(rotation) ?? '0'
  let transform = ''
  switch (_rotation) {
    case '90':
      transform = 'transform: rotate(90deg);'
      break
    case '180':
      transform = 'transform: rotate(180deg);'
      break
    case '270':
      transform = 'transform: rotate(270deg);'
      break
  }

  const d = gcd(width, height)
  const w = width / d
  const h = height / d

  const screenOrientationVertical = ['90', '270'].includes(_rotation)
  const aspectRatio = w / h
  const kh = bbox.height / height
  const style = [
    'position: relative',
    screenOrientationVertical
      ? `height: 100vw; max-height: ${(height / aspectRatio) * kh}px;`
      : `width: ${bbox.height * aspectRatio}px;`,
    `aspect-ratio: ${w}/${h}`,
    transform
  ]
  return style.join(';')
})

const style = computed(() => getLayoutSectionStyleIndex(unref(frame).layout))

const resizeObserver = new ResizeObserver(([entry]) => {
  parentBBox.value = entry.target.getBoundingClientRect()
})

onMounted(() => {
  const parentEl = unref(container)?.parentElement ?? null
  if (parentEl === null) throw new Error('invalid parent')
  resizeObserver.observe(parentEl)
})

onBeforeUnmount(() => {
  resizeObserver.disconnect()
})
</script>
