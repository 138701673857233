<template>
  <template v-if="unregisteredAssetSrc !== null && registeredAssetSrc !== null">
    <transition name="fade" mode="out-in">
      <video
        v-if="registrationStatus === null"
        :poster="VIDEO_POSTER_SRC"
        class="w-full h-full object-cover z-[-1]"
        :src="unregisteredAssetSrc"
        autoplay
        loop
      />
      <video
        v-else
        class="w-full h-full object-cover z-[-1]"
        :poster="VIDEO_POSTER_SRC"
        :src="registeredAssetSrc"
        autoplay
        loop
      />
    </transition>
  </template>
</template>

<script lang="ts" setup>
import { ref, unref, onBeforeUnmount } from 'vue'
import useAssets from '@/composables/useAssets'
import useState from '@/composables/useState'
import { VIDEO_POSTER_SRC } from '@/constants'

const { fetchIdleScreenAssets } = useAssets()
const { registrationStatus } = useState()

const registeredAssetSrc = ref<null | string>(null)
const unregisteredAssetSrc = ref<null | string>(null)

const fetchVideos = async () => {
  const { unregisteredAsset, registeredAsset } = await fetchIdleScreenAssets()
  registeredAssetSrc.value = URL.createObjectURL(registeredAsset.blob)
  unregisteredAssetSrc.value = URL.createObjectURL(unregisteredAsset.blob)
}

onBeforeUnmount(() => {
  if (unref(unregisteredAssetSrc) !== null)
    URL.revokeObjectURL(unref(unregisteredAssetSrc) as string)
  if (unref(registeredAssetSrc) !== null) URL.revokeObjectURL(unref(registeredAssetSrc) as string)
})
fetchVideos()
</script>
