<template>
  <div class="flex-1 flex flex-col justify-center items-center">
    <div class="flex flex-col justify-center items-center gap-4 xl:gap-8">
      <div class="text-2xl xl:text-4xl text-center font-bold tracking-wider">
        A programação irá começar em instantes...
      </div>
      <div>
        <i class="pi pi-spinner-dotted pi-spin text-2xl xl:text-4xl" />
      </div>
    </div>
  </div>
</template>
