<template>
  <div class="h-full w-full relative text-white">
    <video
      v-video-reload="{ show, unmuted }"
      :src="src"
      :poster="VIDEO_POSTER_SRC"
      muted
      loop
      class="h-full w-full bg-black peer/video"
    />
    <span
      class="absolute top-0 right-0 text-shadow-lg shadow-black bg-black/50 p-0.5 rounded-bl-lg opacity-0 peer-data-[muted='true']/video:opacity-100 transition-opacity"
    >
      <i class="pi pi-volume-off" />
      <i class="pi pi-times" />
    </span>
  </div>
</template>

<script lang="ts" setup>
import { VIDEO_POSTER_SRC } from '@/constants'
import useState from '@/composables/useState'
import { type Directive } from 'vue'

defineProps<{ show?: boolean; src: string; unmuted?: boolean }>()

const { resetPlayer } = useState()

// https://developer.chrome.com/blog/play-request-was-interrupted
// https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
const isPlaying = (el: HTMLVideoElement) =>
  el.currentTime > 0 && !el.paused && !el.ended && el.readyState > el.HAVE_CURRENT_DATA

const playVideo = async (el: HTMLVideoElement, unmuted?: boolean) => {
  if (el.muted) el.addEventListener('click', () => playVideo(el, unmuted))
  try {
    try {
      el.muted = !unmuted
      if (!isPlaying(el)) await el.play()
    } catch (_) {
      el.muted = true
      if (!isPlaying(el)) await el.play()
    } finally {
      if (unmuted) el.setAttribute('data-muted', `${el.muted}`)
    }
  } catch (err) {
    console.error('VideoContainer component: error playing video', err)
    await resetPlayer()
    throw err
  }
}

const vVideoReload: Directive<HTMLVideoElement, { show?: boolean; unmuted?: boolean }> = {
  mounted: async (el, binding) => {
    const { show, unmuted } = binding.value
    if (!isPlaying(el) && show) await playVideo(el, unmuted)
  },
  updated: async (el, binding) => {
    const { show, unmuted } = binding.value
    if (!show) {
      if (isPlaying(el)) el.pause()
      el.currentTime = 0
    }
    if (show) await playVideo(el, unmuted)
  },
  beforeUnmount: (el, binding) => {
    const { unmuted } = binding.value
    el.pause()
    el.removeAttribute('src')
    el.load()
    el.remove()
    el.removeEventListener('click', () => playVideo(el, unmuted))
  }
}
</script>
