import * as Sentry from '@sentry/vue'
import useState from '@/composables/useState'
import { watch, unref, type App } from 'vue'
import type { IPlayerRegistrationStatus } from '@vontage/types/v2/player'

export interface ISentryVontagePlayerContext {
  playerId: string
  registration: IPlayerRegistrationStatus | null
  storage: unknown
}
const { playerId, registrationStatus } = useState()

const updateSentryContext = async () => {
  const useAssets = await (await import('@/composables/useAssets')).default

  const { getStorageEstimate } = useAssets()
  const playerContext: ISentryVontagePlayerContext = {
    playerId: unref(playerId),
    registration: JSON.parse(JSON.stringify(unref(registrationStatus))),
    storage: await getStorageEstimate()
  }
  Sentry.setContext('Vontage Player', playerContext)
  console.debug('updated sentry vontage-player context', playerContext)
}

watch([playerId, registrationStatus], updateSentryContext, {
  deep: true,
  immediate: true
})

const init = async (app: App) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/backend\.vontage\.com\.br\/api/,
      /^https:\/\/backend\.staging\.vontage\.com\.br\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: true,
    normalizeDepth: 10
  })
}

const useSentry = () => {
  return {
    init,
    updateSentryContext
  }
}

export default useSentry
