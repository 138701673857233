import { ref, unref, computed } from 'vue'
import { Network, type ConnectionStatus } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'

const networkStatus = ref<ConnectionStatus>({
  connected: window.navigator.onLine,
  connectionType: 'unknown'
})

if (Capacitor.getPlatform() === 'android') {
  Network.addListener('networkStatusChange', (status) => {
    networkStatus.value = status
  })
} else if (Capacitor.getPlatform() === 'web') {
  window.addEventListener('online', () => {
    networkStatus.value = { connected: true, connectionType: 'unknown' }
  })
  window.addEventListener('offline', () => {
    networkStatus.value = { connected: false, connectionType: 'unknown' }
  })
}

export default () => ({
  networkStatus: computed(() => unref(networkStatus))
})
