import { unref, watch } from 'vue'
import { generate } from 'short-uuid'
import useBottleneck from '@/composables/useBottleneck'
import Bottleneck from 'bottleneck'
import useAuth from '@/composables/useAuth'
import useState from '@/composables/useState'
import useNetworkStatus from '@/composables/useNetworkStatus'
import type {
  IPlayerRegistrationResponse,
  IPlayerRegistrationStatus
} from '@vontage/types/v2/player'
import type { IPlayerUpdateEventPayload } from '@vontage/types/manual/player-update'
import type { TScreenRotation } from '@/types'
import type { IPositionMeasurement } from '@vontage/types/v2/geolocation'

export interface ICloseEvent {
  timestamp: Date
  code: number
  reason: string
  wasClean: boolean
}

const bottleneck = useBottleneck()
const { getWebsocketURLCallback } = useAuth()
const {
  connected,
  connecting,
  lastCloseEvent,
  doNotReconnectSocket,
  lastPing,
  pingPeriodMs,
  socketTimeoutMs,
  rtt,
  screenRotation,
  updatePlayer,
  restartPlayer,
  resetPlayer,
  baseURL,
  registrationCode
} = useState()
const { networkStatus } = useNetworkStatus()
const { setPlayerRegistration, setPlayerRegistrationStatus } = useState()

let socket: WebSocket | null = null
let pingTimeout: any = null
let watchdog: any = null

const disconnect = async (params?: { forcefully?: boolean; code?: number; reason?: string }) => {
  const { forcefully, code, reason } = params ?? {}
  if (forcefully === true) doNotReconnectSocket.value = true
  if (socket !== null && socket.readyState === socket.OPEN) socket?.close(code, reason)
  socket = null
}

const sendPing = async () => {
  if (socket === null) await connect()
  if (socket === null) throw new Error('could not connect')
  socket.send('ping')
  lastPing.value = new Date().getTime()
  if (watchdog !== null) clearTimeout(watchdog)
  watchdog = setTimeout(() => {
    console.warn('pong timeout...')
    connected.value = false
    disconnect()
  }, unref(socketTimeoutMs))
}

export const sendPosition = async (position: IPositionMeasurement) =>
  socket?.send(JSON.stringify({ type: 'position', payload: position }))

const connectedEventHandler = async (state: boolean) => {
  if (socket === null && state === true)
    throw new Error(`connectedEventHander: null socket with connection state ${state}`)
  connected.value = state
  registrationCode.value = null
  if (state === true) {
    if (unref(connecting)) connecting.value = false
    await sendPing()
    socket?.send(JSON.stringify({ type: 'registration' }))
  } else {
    if (pingTimeout !== null) {
      clearTimeout(pingTimeout)
      pingTimeout = null
    }
    if (watchdog !== null) {
      clearTimeout(watchdog)
      watchdog = null
    }
    if (!unref(doNotReconnectSocket) && unref(networkStatus).connected) connect()
  }
}

const processMessageEvent = (event: MessageEvent) => {
  const rawMsg = event.data
  if (typeof rawMsg !== 'string') {
    console.warn('invalid socket message', typeof rawMsg)
    return
  } else if (rawMsg === 'pong') {
    const now = new Date().getTime()
    rtt.value = now - (unref(lastPing) ?? now)
    if (watchdog !== null) clearTimeout(watchdog)
    pingTimeout = setTimeout(async () => await sendPing(), unref(pingPeriodMs))
    return
  }
  const msg: any = JSON.parse(rawMsg)
  if (
    typeof msg === 'object' &&
    msg !== null &&
    'type' in msg &&
    typeof msg.type === 'string' &&
    'payload' in msg
  ) {
    switch (msg.type) {
      // received during player registration or standby phase
      case 'registration':
        setPlayerRegistration(msg.payload as IPlayerRegistrationResponse | string | null)
        break
      case 'registration-status':
        setPlayerRegistrationStatus(msg.payload as IPlayerRegistrationStatus)
        break
      case 'player-update-available':
        updatePlayer(msg.payload as IPlayerUpdateEventPayload)
        break
      case 'set-screen-rotation':
        screenRotation.value = msg.payload as TScreenRotation
        break
      case 'restart':
        restartPlayer()
        break
      case 'reset':
        resetPlayer()
        break
      default:
        console.warn('unknown msg type', msg.type, msg.payload)
    }
  } else console.warn('invalid socket message', rawMsg)
}

const connectTask = async () => {
  if (socket !== null && socket.readyState === socket.OPEN) return
  const sessionURL = await getWebsocketURLCallback()
  return await new Promise<void>((resolve, reject) => {
    console.debug('Connecting socket...')

    socket = new WebSocket(sessionURL.toString())
    socket.addEventListener('open', () => {
      console.debug('Socket connected!')
      connectedEventHandler(true)
      resolve()
    })
    socket.addEventListener('error', () => {
      console.debug('Socket error!')
    })
    socket.addEventListener('close', (event: CloseEvent) => {
      bottleneck.incrementReservoir(1)
      const { code, reason, wasClean } = event
      console.debug(`Socket closed: ${JSON.stringify(event)}`)
      connectedEventHandler(false)

      lastCloseEvent.value = {
        timestamp: new Date(),
        code,
        reason,
        wasClean
      }
      reject(new Error('socket closed'))
    })
    socket.addEventListener('message', processMessageEvent)
  })
}

const connect = async (forceFully?: boolean) => {
  if (forceFully) doNotReconnectSocket.value = false
  if (socket !== null && socket?.readyState === socket.OPEN) return
  const id = `connect-${generate()}`
  await bottleneck.schedule({ id }, connectTask).catch((err) => {
    if (err instanceof Bottleneck.BottleneckError) {
      console.warn(`Bottneck error: ${id} ${err.message}`)
    } else throw err
  })
}

watch(
  networkStatus,
  (status) => {
    if (status.connected) connect()
    else {
      connected.value = false
      connecting.value = false
      if (pingTimeout !== null) {
        clearTimeout(pingTimeout)
        pingTimeout = null
      }
      if (watchdog !== null) {
        clearTimeout(watchdog)
        watchdog = null
      }
      disconnect()
    }
  },
  { immediate: true }
)

watch(baseURL, () => disconnect())

const useWebsocket = () => {
  return {
    sendPosition
  }
}

export default useWebsocket
