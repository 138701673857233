import { ref, computed, unref } from 'vue'
import { uuid } from 'short-uuid'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { SOCKET_TIMEOUT_MS, PING_PERIOD_MS } from '@/constants'
import type { TScreenRotation, ICloseEvent } from '@/types'
import type { IPlayerUpdateEventPayload } from '@vontage/types/manual/player-update'
import type { IPlayerRegistrationResponse } from '@vontage/types/v2/player'
import type { IPlayerRegistrationStatus } from '@vontage/types/v2/player'

const PLAYER_ID_PREFIX = 'VONTAGE:PLAYER:ID'
const PLAYER_STAGING_PREFIX = 'VONTAGE:PLAYER:STAGING'
const PLAYER_REGISTRATION = 'VONTAGE:TENANT:REGISTRATION'
const SCREEN_ROTATION_PREFIX = 'VONTAGE:SCREEN_ROTATION'

export const getStoredScreenRotation = (): TScreenRotation => {
  const screenRotation = window.localStorage.getItem(SCREEN_ROTATION_PREFIX) ?? '0'
  return screenRotation as TScreenRotation
}

const getPlayerId = (): string => {
  let playerId = window.localStorage.getItem(PLAYER_ID_PREFIX) ?? null
  if (playerId === null) {
    playerId = uuid()
    window.localStorage.setItem(PLAYER_ID_PREFIX, playerId)
  }
  return playerId
}

const getCurrentURLS = (mode: string) => {
  let apiUrl = ''
  let appUrl = ''
  switch (mode) {
    case 'development':
      apiUrl = 'http://192.168.15.4:8088/api/v1/player'
      appUrl = 'http://192.168.15.4:5174'
      break
    case 'staging':
      apiUrl = 'https://backend.staging.vontage.com.br/api/v1/player'
      appUrl = 'https://app.staging.vontage.com.br'
      break
    case 'production':
      apiUrl = 'https://backend.vontage.com.br/api/v1/player'
      appUrl = 'https://app.vontage.com.br'
      break
  }
  return { apiUrl, appUrl }
}

const setScreenRotation = (rotation: TScreenRotation) => {
  window.localStorage.setItem(SCREEN_ROTATION_PREFIX, rotation)
  screenRotation.value = rotation
  // websocketClient.setScreenRotation(rotation)
  // TODO: IMPLEMENT REST ENDPOINT
}

const rotateScreenLeft = () => {
  let newRotation: TScreenRotation = '0'
  switch (unref(screenRotation)) {
    case '0':
      newRotation = '270'
      break
    case '90':
      newRotation = '0'
      break
    case '180':
      newRotation = '90'
      break
    case '270':
      newRotation = '180'
      break
  }
  setScreenRotation(newRotation)
}

const rotateScreenRight = () => {
  let newRotation: TScreenRotation = '0'
  switch (unref(screenRotation)) {
    case '0':
      newRotation = '90'
      break
    case '90':
      newRotation = '180'
      break
    case '180':
      newRotation = '270'
      break
    case '270':
      newRotation = '0'
      break
  }
  setScreenRotation(newRotation)
}

const screenOrientation = computed(() => {
  const rotation = unref(screenRotation)
  const horizontalAngles = ['0', '180']
  return horizontalAngles.includes(rotation) ? 'horizontal' : 'vertical'
})

const updatePlayer = async (update: IPlayerUpdateEventPayload) => {
  const { key, version, jwt } = update
  console.debug('New player update available', { key, version })
  if (unref(mode) !== 'development') {
    const url = `${unref(baseURL)}/updates/${jwt}`
    const data = await CapacitorUpdater.download({ url, version })
    await CapacitorUpdater.set({ id: data.id })
    console.debug(`App will reload with next version => ${JSON.stringify(data, null, 2)}`)
  }
}

const restartPlayer = async () => {
  console.warn('Got restart message...')
  if (Capacitor.getPlatform() !== 'web') await CapacitorUpdater.reload()
  else location.reload()
}

const resetPlayer = async () => {
  console.warn('Got reset message...')
  if (Capacitor.getPlatform() !== 'web') await CapacitorUpdater.reset()
  else location.reload()
}

const getPlayerRegistration = (): { tenantId: string; secret: string } | null => {
  const raw = window.localStorage.getItem(PLAYER_REGISTRATION) ?? null
  const value = raw === null ? null : (JSON.parse(raw) as IPlayerRegistrationResponse)
  return value
}

const setPlayerRegistration = (value: IPlayerRegistrationResponse | string | null) => {
  if (typeof value === 'string' || value === null) {
    window.localStorage.removeItem(PLAYER_REGISTRATION)
    secret.value = null
    registrationStatus.value = null
    if (typeof value === 'string') registrationCode.value = value
  } else {
    window.localStorage.setItem(PLAYER_REGISTRATION, JSON.stringify(value))
    tenantId.value = value.tenantId
    secret.value = value.secret
    registrationCode.value = null
  }
}

const setPlayerRegistrationStatus = (value: IPlayerRegistrationStatus) => {
  registrationStatus.value = value
  campaignT0.value = value.campaign?.t0 ?? null
  console.debug('Got registration status!')
}

const registration = getPlayerRegistration()
const mode = ref(
  window.localStorage.getItem(PLAYER_STAGING_PREFIX) ? 'staging' : import.meta.env.MODE
)
const baseURL = computed(() => getCurrentURLS(unref(mode)).apiUrl)
const playerId = computed(() => getPlayerId())

const tenantId = ref(registration?.tenantId ?? null)
const secret = ref(registration?.secret ?? null)
const registrationStatus = ref<IPlayerRegistrationStatus | null>(null)
const registrationCode = ref<string | null>(null)
const campaignT0 = ref<number | null>(null)
const version = __APP_VERSION__
const release = __APP_RELEASE__
const platform = Capacitor.getPlatform()
const screenRotation = ref<TScreenRotation>(getStoredScreenRotation())
const pingPeriodMs = ref(PING_PERIOD_MS)
const socketTimeoutMs = ref(SOCKET_TIMEOUT_MS)
const connecting = ref(false)
const reconnectAttempts = ref(0)
const connected = ref(false)
const lastCloseEvent = ref<ICloseEvent | null>(null)
const doNotReconnectSocket = ref(false)
const lastPing = ref<number | null>(null)
const rtt = ref<number | null>(null)
const screenSize = ref<{ width: number; height: number } | null>(null)
const isFullScreen = ref(false)
const mouseMoving = ref(false)
const showDebugModal = ref(false)
const updatingSoftware = ref(false)

const printAppVersionConsoleBanner = async () => {
  console.log(`Vontage Player ${__APP_RELEASE__} v${__APP_VERSION__} ${import.meta.env.MODE}`)
}

const setScreenSize = () => {
  const { innerHeight: height, innerWidth: width } = window
  screenSize.value = { height, width }
}

const toggleStagingMode = () => {}
/*
const toggleStagingMode = () => {
  if (import.meta.env.MODE === 'staging') return
  mode.value = unref(mode) === 'staging' ? import.meta.env.MODE : 'staging'
  if (unref(mode) === 'staging') window.localStorage.setItem(PLAYER_STAGING_PREFIX, 'true')
  else window.localStorage.removeItem(PLAYER_STAGING_PREFIX)
}
*/

const toggleFullScreen = () => {
  document.fullscreenElement === null
    ? document.documentElement.requestFullscreen()
    : document.exitFullscreen()
}

if (unref(screenSize) === null) {
  window.onresize = () => setScreenSize()
  setScreenSize()
}

const useState = () => {
  return {
    printAppVersionConsoleBanner,
    rotateScreenLeft,
    rotateScreenRight,
    updatePlayer,
    restartPlayer,
    resetPlayer,
    toggleStagingMode,
    toggleFullScreen,
    setPlayerRegistration,
    getPlayerRegistration,
    setPlayerRegistrationStatus,
    getPlayerId,
    mode: computed(() => unref(mode)),
    baseURL,
    appUrl: computed(() => getCurrentURLS(unref(mode)).appUrl),
    playerId,
    tenantId: computed(() => unref(tenantId)),
    secret: computed(() => unref(secret)),
    version,
    release,
    platform,
    screenOrientation,
    appVersion: computed(() => ({
      version: __APP_VERSION__,
      release: __APP_RELEASE__,
      timestamp: __APP_BUILD_TIMESTAMP__,
      mode: import.meta.env
    })),
    screenRotation: computed({
      get: () => unref(screenRotation),
      set: setScreenRotation
    }),
    pingPeriodMs: computed({
      get: () => unref(pingPeriodMs),
      set: (value: number) => {
        pingPeriodMs.value = value
      }
    }),
    socketTimeoutMs: computed({
      get: () => unref(socketTimeoutMs),
      set: (value: number) => {
        socketTimeoutMs.value = value
      }
    }),
    connecting: computed({
      get: () => unref(connecting),
      set: (value: boolean) => {
        connecting.value = value
      }
    }),
    reconnectAttempts: computed({
      get: () => unref(reconnectAttempts),
      set: (value: number) => {
        reconnectAttempts.value = value
      }
    }),
    connected: computed({
      get: () => unref(connected),
      set: (value: boolean) => {
        connected.value = value
      }
    }),
    lastCloseEvent: computed({
      get: () => unref(lastCloseEvent),
      set: (value: ICloseEvent | null) => {
        lastCloseEvent.value = value
      }
    }),
    doNotReconnectSocket: computed({
      get: () => unref(doNotReconnectSocket),
      set: (value: boolean) => {
        doNotReconnectSocket.value = value
      }
    }),
    lastPing: computed({
      get: () => unref(lastPing),
      set: (value: number | null) => {
        lastPing.value = value
      }
    }),
    rtt: computed({
      get: () => unref(rtt),
      set: (value: number | null) => {
        rtt.value = value
      }
    }),
    registrationStatus: computed({
      get: () => unref(registrationStatus),
      set: (value: IPlayerRegistrationStatus | null) => {
        registrationStatus.value = value
      }
    }),
    registrationCode: computed({
      get: () => unref(registrationCode),
      set: (value: string | null) => {
        registrationCode.value = value
      }
    }),
    campaignT0: computed(() => unref(campaignT0)),
    mouseMoving: computed({
      get: () => unref(mouseMoving),
      set: (value: boolean) => {
        mouseMoving.value = value
      }
    }),
    isFullScreen: computed({
      get: () => unref(isFullScreen),
      set: (value: boolean) => {
        isFullScreen.value = value
      }
    }),
    showDebugModal: computed({
      get: () => unref(showDebugModal),
      set: (value: boolean) => {
        showDebugModal.value = value
      }
    }),
    updatingSoftware: computed({
      get: () => unref(updatingSoftware),
      set: (value: boolean) => {
        updatingSoftware.value = value
      }
    })
  }
}

export default useState
