<template>
  <div
    v-if="typeof registrationStatus === 'object' && registrationStatus !== null"
    class="flex-1 flex flex-col justify-center items-center"
  >
    <div class="flex flex-col items-center gap-4 xl:gap-8">
      <div class="text-5xl xl:text-7xl font-black tracking-wider text-center">
        {{ registrationStatus.tenant.name }}
      </div>

      <transition name="fade" mode="out-in">
        <div
          :key="registrationStatus.campaign?.metadata.title ?? ''"
          class="text-2xl xl:text-4xl font-black tracking-wider text-center"
        >
          {{
            !registrationStatus?.campaign
              ? 'Sem campanha atribuída'
              : registrationStatus.campaign.metadata.title
          }}
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div
          v-if="registrationStatus.tenant.quotaExcess"
          class="text-sm xl:text-xl font-bold tracking-wide bg-red-800 px-2 rounded-md"
        >
          <i class="pi pi-exclamation-triangle" />
          Limite do plano excedido!
        </div>
        <div v-else class="text-sm xl:text-xl font-extralight tracking-wide animate-pulse">
          Aguardando programação
        </div>
      </transition>

      <div class="flex flex-col items-center xl:gap-2">
        <transition name="fade" mode="out-in">
          <div
            :key="registrationStatus?.playerMetadata.name ?? ''"
            class="text-3xl xl:text-5xl font-semibold tracking-wider text-center"
          >
            {{ registrationStatus?.playerMetadata.name }}
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div
            :key="playerId.split('-')[0]"
            class="text-sm xl:text-xl font-mono tracking-wider text-center"
          >
            #{{ playerId.split('-')[0] }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useState from '@/composables/useState'

const { registrationStatus, playerId } = useState()
</script>
