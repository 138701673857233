<template>
  <div class="flex-1 flex flex-col justify-center items-center">
    <div
      class="animate-pulse px-2 py-1 xl:px-4 xl:py-2 rounded-md text-xl xl:text-3xl font-bold tracking-wider flex items-baseline gap-1  bg-red-500 text-yellow-200 text-shadow shadow-black"
    >
      <i class="pi pi-exclamation-triangle text-xl xl:text-3xl" />
      <span>Falha na ligação de rede!</span>
    </div>
  </div>
</template>
