<template>
  <transition name="fade" mode="out-in">
    <waiting-buffer-screen
      v-if="frameBuffer.length === 0"
      :show-clock="standByFrameCount === queue.length"
    >
      <transition name="fade-slow" mode="out-in">
        <presentation-will-start-soon v-if="frameBuffer.length || isNaN(downloadProgress)" />
        <buffering-screen v-else :progress="downloadProgress" />
      </transition>
    </waiting-buffer-screen>
    <div v-else>
      <v-buffer-frame
        v-for="(frame, i) in frameBuffer"
        :key="i"
        :frame="frame"
        :rotation="rotation"
        :asset-index="assetIndex"
        :show="viewIdx === i"
        :class="[viewIdx === i ? 'z-10' : 'z-0']"
        @playing="playingFrame = frame"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { ref, unref, watch, toRefs, onBeforeUnmount, computed, h, type VNode } from 'vue'
import VBufferFrame from '@/components/VBufferFrame.vue'
import BufferingScreen from '@/components/BufferingScreen.vue'
import PresentationWillStartSoon from '@/components/PresentationWillStartSoon.vue'
import useScheduler from '@/composables/useScheduler'
import useAssets from '@/composables/useAssets'
import useState from '@/composables/useState'
import VideoContainer from '@/components/VideoContainer.vue'
import WaitingBufferScreen from '@/components/WaitingBufferScreen.vue'
import Bottleneck from 'bottleneck'
import { queueUpdater } from '@/composables/useWorkers'
import { proxy } from 'comlink'
import type { IQueueFrame, IAssetIndex } from '@/types'
import type { TRotation } from './VFrame.vue'

const props = defineProps<{ queue: IQueueFrame[]; rotation?: TRotation }>()

const { queue, rotation } = toRefs(props)

const MAX_RETRIES = 3

const limiter = new Bottleneck({
  maxConcurrent: 1,
  highWater: 1,
  strategy: Bottleneck.strategy.LEAK
})

limiter.on('failed', (error, jobInfo) => {
  const delay = Math.pow(2, jobInfo.retryCount) + Math.random() * 1000
  if (jobInfo.retryCount < MAX_RETRIES - 1) return delay
})

const { playingFrame } = useScheduler()
const { fetchAssets, assetDownloadProgressIndex, downloadProgress } = useAssets()
const { campaignT0 } = useState()

const frameBuffer = ref<IQueueFrame[]>([])
const standByFrameCount = ref(0)

const viewIdx = ref(-1)
const assetIndex = ref<IAssetIndex>({})

const buildAssetIndex = async () => {
  const assetIds = unref(requiredAssetIds)
  const assets = await fetchAssets(assetIds)
  for (const { urn, blob } of assets) {
    if (!unref(assetIndex)[urn]) {
      const src = URL.createObjectURL(blob)
      let vnode: VNode | null = null
      if (blob.type.includes('image'))
        vnode = h('div', {
          class: 'w-full h-full bg-cover',
          style: `background-image: url(${src})`
        })
      else if (blob.type.includes('video')) {
        vnode = h(VideoContainer, {
          src
        })
      } else if (blob.type === 'text/html')
        vnode = h('iframe', {
          class: 'w-full h-full',
          srcdoc: await blob.text()
        })
      if (vnode === null) throw new Error(`asset ${urn}: invalid type ${blob.type}`)
      unref(assetIndex)[urn] = { src, blob, vnode }
    }
  }
  const assetsToRevoke = Object.keys(unref(assetIndex)).filter(
    (assetId) => !assetIds.includes(assetId)
  )
  assetsToRevoke.forEach((assetId) => {
    const { src = '' } = unref(assetIndex)?.[assetId] ?? {}
    if (src.length > 0) URL.revokeObjectURL(src)
    delete unref(assetIndex)[assetId]
  })
}

const requiredAssetIds = computed(() =>
  Array.from(
    new Set(
      Object.values(unref(queue)).flatMap(({ facets }) =>
        Object.values(facets).map(({ asset }) => asset)
      )
    )
  )
)

watch(
  [queue, assetIndex, campaignT0],
  async ([queue, assetIndex, campaignT0]) => {
    await queueUpdater.setState(JSON.parse(JSON.stringify({ queue, assetIndex, campaignT0 })))
  },
  { immediate: true, deep: true }
)

const initWorker = async () => {
  await queueUpdater.setPlaybackReadyListener(
    proxy((ready: boolean) => {
      if (ready) queueUpdater.startTicker()
    })
  )
  await queueUpdater.setFrameTickerListener(
    proxy((pointer: number) => {
      viewIdx.value = pointer
    })
  )
  frameBuffer.value = await queueUpdater.setFrameBufferListener(
    proxy((value: IQueueFrame[]) => {
      frameBuffer.value = value
    })
  )

  standByFrameCount.value = await queueUpdater.setStandByFrameListener(
    proxy((value: number) => {
      standByFrameCount.value = value
    })
  )
}

watch(
  requiredAssetIds,
  () =>
    limiter.schedule(buildAssetIndex).catch((err) => {
      console.error('error while building asset index...', err)
      throw err
    }),
  { immediate: true }
)

watch(
  [requiredAssetIds, assetDownloadProgressIndex],
  ([assetIds, assetDownloadProgressIndex]) => {
    const progress = assetIds.reduce(
      (accumulator, assetId) => {
        const { loaded, total } = assetDownloadProgressIndex?.[assetId] ?? {
          loaded: 0,
          total: 0,
          progress: 0
        }
        accumulator.loaded += loaded
        accumulator.total += total
        const currentProgress = accumulator.loaded / accumulator.total
        accumulator.progress = currentProgress
        return accumulator
      },
      { loaded: 0, total: 0, progress: 0 }
    )
    downloadProgress.value = progress.progress
  },
  { immediate: true, deep: true }
)

initWorker()

onBeforeUnmount(async () => {
  playingFrame.value = null
  Object.values(unref(assetIndex)).forEach(({ src }) => URL.revokeObjectURL(src))

  assetIndex.value = {}
  await queueUpdater.finalizer()
})
</script>
