import { unref, watch } from 'vue'
import { Capacitor } from '@capacitor/core'
import useState from '@/composables/useState'
import { Logtail } from '@logtail/browser'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'

const { registrationStatus, playerId, mode } = useState()
const getRegistrationStatus = () => unref(registrationStatus)

const warn = console.warn
const info = console.info
const error = console.error
const debug = console.debug

const overrideConsoleMethods = (logtail?: Logtail) => {
  console.warn = (...data) => {
    logtail?.warn(...data)
    warn(...data)
  }
  console.info = (...data) => {
    logtail?.info(...data)
    info(...data)
  }
  console.error = (...data) => {
    logtail?.error(...data)
    error(...data)
  }
  console.debug = (...data) => {
    logtail?.debug(...data)
    debug(...data)
  }
}

const setLogtail = (mode: string) => {
  if (mode !== 'development') {
    const logtail = new Logtail(import.meta.env.VITE_LOGTAIL_SOURCE_TOKEN)
    logtail.use(async (log) => {
      return {
        ...log,
        environment: import.meta.env.MODE,
        playerId: unref(playerId),
        registrationStatus: getRegistrationStatus(),
        buildTimestamp: __APP_BUILD_TIMESTAMP__,
        app: Capacitor.getPlatform() === 'web' ? null : await App.getInfo(),
        device: await Device.getInfo()
      }
    })
    overrideConsoleMethods(logtail)
  } else overrideConsoleMethods()
}

watch(mode, (mode) => setLogtail(mode), { immediate: true })
