<template>
  <div
    class="absolute text-black"
    :class="{
      'h-screen w-screen': screenOrientation === 'horizontal',
      ' h-[100vw] w-[100vh]': screenOrientation === 'vertical',
      '': screenRotation === '0',
      'rotate-90 origin-bottom-left -translate-y-full': screenRotation === '90',
      'rotate-180': screenRotation === '180',
      '-rotate-90  origin-top-right -translate-x-full': screenRotation === '270'
    }"
  >
    <div class="relative w-full h-full">
      <video-background />
      <div
        class="absolute bottom-0 inset-x-1/2 -translate-x-1/2 h-4/5 w-full flex flex-col justify-between items-center gap-8 text-white text-shadow shadow-black"
        :class="{
          'max-w-lg xl:max-w-3xl': screenOrientation === 'horizontal',
          'max-w-sm xl:max-w-xl': screenOrientation === 'vertical'
        }"
      >
        <vontage-logo class="shrink-0 w-full max-w-md xl:max-w-2xl px-8" />
        <transition name="fade" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// import AnimatedBackground from '@/components/AnimatedBackground.vue'
import VideoBackground from '@/components/VideoBackground.vue'
import VontageLogo from '@/assets/svg/vontage-logo.svg'
import useState from '@/composables/useState'

const { screenRotation, screenOrientation } = useState()
</script>
