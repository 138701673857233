<template>
  <div
    class="absolute text-white text-shadow shadow-black"
    :class="{
      'h-screen w-screen': screenOrientation === 'horizontal',
      ' h-[100vw] w-[100vh]': screenOrientation === 'vertical',
      '': screenRotation === '0',
      'rotate-90 origin-bottom-left -translate-y-full': screenRotation === '90',
      'rotate-180': screenRotation === '180',
      '-rotate-90  origin-top-right -translate-x-full': screenRotation === '270'
    }"
  >
    <div class="relative h-full w-full">
      <video
        v-if="backgroundSrc !== null"
        :poster="VIDEO_POSTER_SRC"
        class="h-full w-full object-cover z-[-1]"
        :src="backgroundSrc"
        autoplay
        loop
      />
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="!hibernate"
        class="absolute bottom-0 inset-x-1/2 -translate-x-1/2 h-4/5 w-full flex flex-col items-center gap-8 text-white text-shadow shadow-black"
        :class="{
          'max-w-lg xl:max-w-3xl': screenOrientation === 'horizontal',
          'max-w-sm xl:max-w-xl': screenOrientation === 'vertical'
        }"
      >
        <vontage-logo class="shrink-0 w-full max-w-md xl:max-w-2xl px-8" />
        <transition name="fade" mode="out-in">
          <div v-if="showClock" class="w-full h-full flex items-center justify-center">
            <div class="font-black text-5xl xl:text-7xl tabular-nums">
              {{ [hours, mins, seconds].join(' : ') }}
            </div>
          </div>

          <slot v-else />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, toRefs, unref, onBeforeUnmount } from 'vue'
import VontageLogo from '@/assets/svg/vontage-logo.svg'
import useState from '@/composables/useState'
import useAssets from '@/composables/useAssets'
import { VIDEO_POSTER_SRC } from '@/constants'

const props = defineProps<{ showClock: boolean }>()
const { showClock } = toRefs(props)

const { screenRotation, screenOrientation } = useState()
const { fetchLocalAsset } = useAssets()

const backgroundSrc = ref<string | null>(null)

const hours = ref('00')
const mins = ref('00')
const seconds = ref('00')
const hibernate = ref(false)

const fetchBackground = async () => {
  const asset = await fetchLocalAsset('/videos/black_minimalist.mp4')
  backgroundSrc.value = URL.createObjectURL(asset.blob)
}

const tick = () => {
  const date = new Date()
  hours.value = String(date.getHours()).padStart(2, '0')
  mins.value = String(date.getMinutes()).padStart(2, '0')
  seconds.value = String(date.getSeconds()).padStart(2, '0')
}

tick()
setInterval(() => tick(), 100)

let timeout: ReturnType<typeof setTimeout> | null = null

watch(
  showClock,
  (showClock) => {
    hibernate.value = false
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
    if (showClock)
      timeout = setTimeout(() => {
        hibernate.value = true
      }, 60000)
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  if (unref(backgroundSrc) !== null) URL.revokeObjectURL(unref(backgroundSrc) as string)
  if (timeout !== null) clearTimeout(timeout)
})

fetchBackground()
</script>
