import { createApp, unref } from 'vue'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import '@/composables/initPlayer'
import useState from '@/composables/useState'
import App from '@/App.vue'
import useSentry from '@/composables/useSentry'
import '@/assets/main.css'

window.addEventListener('error', (error) => {
  // const { resetPlayer } = useState()
  console.error('got unhandled error', error)
  // console.warn('resetting player...')
  /// resetPlayer()
})

window.addEventListener('unhandledrejection', (event) => {
  // const { resetPlayer } = useState()
  const { type, reason } = event
  console.error('got unhandled error', type, reason)
  // console.warn('resetting player...')
  // resetPlayer()
})

window.onerror = (
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) => {
  // const { resetPlayer } = useState()
  console.error('got unhandled error', { source, lineno, colno, error, event })
  // console.warn('resetting player...')
  // resetPlayer()
}

const { printAppVersionConsoleBanner, mode } = useState()
const { init: initSentry } = useSentry()

if (Capacitor.getPlatform() !== 'web') CapacitorUpdater.notifyAppReady()
printAppVersionConsoleBanner()

const app = createApp(App)

if (['production', 'staging'].includes(unref(mode))) initSentry(app)

app.mount('#app')
