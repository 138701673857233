import { unref } from 'vue'
import { SignJWT } from 'jose'
import useState from '@/composables/useState'
import useAxios from '@/composables/useAxios'
import { AxiosError } from 'axios'
import type { IPlayerUpdateEventPayload } from '@vontage/types/v2/player-update'

const axios = useAxios()
const {
  playerId,
  tenantId,
  baseURL,
  version,
  platform,
  pingPeriodMs: pingPeriod,
  socketTimeoutMs: socketTimeout,
  getPlayerRegistration,
  setPlayerRegistration,
  updatePlayer,
  updatingSoftware
} = useState()

const getWebsocketSessionToken = async (): Promise<string> => {
  const registration = getPlayerRegistration()
  const headers: Record<string, string> = {
    'x-player-id': unref(playerId)
  }
  if (registration !== null)
    Object.assign(headers, {
      'x-tenant-id': registration.tenantId,
      authorization: `Bearer ${await getBearerToken()}`
    })
  try {
    const {
      token,
      pingPeriodMs,
      socketTimeoutMs,
      update = null
    } = await axios
      .post<{
        token: string
        pingPeriodMs: number
        socketTimeoutMs: number
        update?: IPlayerUpdateEventPayload
      }>(
        '/ws',
        { version, platform },
        {
          headers
        }
      )
      .then(({ data }) => data)
    pingPeriod.value = pingPeriodMs
    socketTimeout.value = socketTimeoutMs
    if (update !== null) {
      try {
        updatingSoftware.value = true
        updatePlayer(update)
      } finally {
        updatingSoftware.value = false
      }
    }
    return token
  } catch (err) {
    if (err instanceof AxiosError) {
      const { status = null } = err?.response?.data ?? {}
      if (status === 403 || status === 401) {
        console.warn(`Invalid tenantId for this player: ${unref(tenantId)}`)
        setPlayerRegistration(null)
      }
    }
    throw err
  }
}

const getWebsocketURLCallback = async (): Promise<URL> => {
  const sessionToken = await getWebsocketSessionToken()
  const url = new URL(unref(baseURL))
  url.protocol = url.protocol === 'https:' ? 'wss' : 'ws'
  url.pathname = url.pathname + `/ws/${sessionToken}`
  return url
}

const getBearerToken = async () => {
  const registration = getPlayerRegistration()
  if (registration === null) throw new Error('player_not_registered')
  const { secret, tenantId } = registration
  if (import.meta.env.MODE === 'development')
    return btoa(JSON.stringify({ playerId: unref(playerId), secret }))

  const jwt = await new SignJWT()
    .setProtectedHeader({ alg: 'HS256' })
    .setAudience(tenantId)
    .setIssuedAt()
    .setSubject(unref(playerId))
    .setExpirationTime('30s')
    .sign(new TextEncoder().encode(secret))
  return jwt
}

// this method is used to disconnect the player on the beforeunload events
// i.e. when the user closes the tab or navigator
const disconnectOnBeforeUnload = async () => {
  const apiUrl = new URL(unref(baseURL))
  apiUrl.pathname = '/ws'
  await fetch(apiUrl, {
    method: 'DELETE',
    keepalive: true,
    headers: {
      'x-player-id': unref(playerId),
      Authorization: `Bearer ${await getBearerToken()}`
    }
  })
}

const useAuth = () => {
  return {
    getBearerToken,
    getWebsocketURLCallback,
    disconnectOnBeforeUnload
  }
}

export default useAuth
