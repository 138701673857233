import { Capacitor } from '@capacitor/core'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen'
import useGeolocation from '@/composables/useGeolocation'
import '@/composables/useLogtail'
import '@/composables/useWebsocket'

const { start: startGeolocationService } = useGeolocation()

const init = async () => {
  const platform = Capacitor.getPlatform()
  if (platform !== 'web') {
    const keepAwakeSupported = await KeepAwake.isSupported()
    if (keepAwakeSupported) await KeepAwake.keepAwake()
  }
  if (platform === 'android') {
    const isFullScreenSupported = await AndroidFullScreen.isImmersiveModeSupported()
    if (isFullScreenSupported) await AndroidFullScreen.immersiveMode()
  }
  startGeolocationService()
}

init()
