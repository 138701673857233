<template>
  <v-frame :frame="frame" :rotation="rotation">
    <template
      v-for="section of Object.keys(frame.facets)"
      :key="section"
      v-slot:[section]="{ facet }"
    >
      <template v-if="assetIndex[facet.asset]?.vnode">
        <component
          v-if="assetIndex[facet.asset].vnode"
          :is="assetIndex[facet.asset].vnode"
          :show="show"
          :unmuted="facet.unmuted ?? false"
        />
      </template>
      <div v-else />
    </template>
  </v-frame>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'
import VFrame from '@/components/VFrame.vue'

import type { IQueueFrame, IAssetIndex } from '@/types'
import type { TRotation } from '@/components/VFrame.vue'

const props = defineProps<{
  frame: IQueueFrame
  rotation?: TRotation
  show: boolean
  assetIndex: IAssetIndex
}>()
const { frame, show, assetIndex } = toRefs(props)
</script>
