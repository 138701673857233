const prettyBytes = (size: unknown) => {
  if (typeof size !== 'number') return null
  const _size = size
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const neg = _size < 0
  if (neg) size = -size
  if (_size < 1) return neg ? '-' : '' + _size + ' B'
  const exponent = Math.min(Math.floor(Math.log(_size) / Math.log(1000)), units.length - 1)
  return `${neg ? '-' : ''}${(_size / Math.pow(1000, exponent)).toLocaleString('pt-BR', {
    maximumFractionDigits: 2
  })} ${units[exponent]}`
}
export default prettyBytes
