<template>
  <div
    class="z-50 bg-black/80 text-[8px] xl:text-xs text-shadow-sm shadow-black px-2 py-1 rounded-md flex flex-col items-start xl:gap-0.5 font-mono"
    :class="containerClasses"
  >
    <strong>Vontage Player v{{ appVersion.version }}</strong>
    <div class="flex items-center gap-1">
      <span class="pi pi-map-marker" />
      <span
        :class="['transition-colors', geolocationRunning ? 'text-green-500' : 'text-yellow-500']"
      >
        {{
          geolocationRunning
            ? dms ?? 'aguardando localização...'
            : geolocationError?.message ?? 'desativado'
        }}
      </span>
      <span>#{{ updates }}</span>
    </div>
    <div><strong>Hora atual:</strong> {{ currentTime }}</div>

    <div><strong>Latência:</strong> {{ roundTripTime ?? 'não conectado' }}</div>
    <div><strong>Rotação da tela:</strong> {{ screenRotation }}°</div>
    <div><strong>ESN:</strong> #{{ playerId.split('-')[0] }}</div>
    <transition
      enter-from-class="max-h-0 opacity-0"
      enter-to-class="max-h-[300px] opacity-100"
      enter-active-class="transition-all duration-[2s]"
      leave-from-class="max-h-[300px] opacity-100"
      leave-to-class="max-h-0 opacity-0"
      leave-active-class="transition-all duration-1000"
    >
      <div v-if="registrationStatus !== null">
        <div><strong>Nome:</strong> {{ registrationStatus?.playerMetadata.name }}</div>
        <div>
          <strong>Organização:</strong> {{ registrationStatus?.tenant.name ?? 'não registrado' }}
        </div>
        <div>
          <strong>Campanha:</strong>
          {{ registrationStatus?.campaign?.metadata?.title ?? 'não atribuida' }}
        </div>
        <div class="flex items-center gap-1">
          <strong
            >Players
            {{ registrationStatus.campaign?.synchronizedPlayers ? '' : 'não' }}
            sincronizados</strong
          >
        </div>
      </div>
    </transition>
    <div class="btn-group">
      <template v-for="(btn, i) in buttons" :key="i">
        <button v-if="btn.if ?? true" class="btn" @click="btn.fn()" :data-active="idx === i">
          <i :class="btn.icon" />
        </button>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref, ref, onBeforeUnmount } from 'vue'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { NativeSettings, AndroidSettings } from 'capacitor-native-settings'
import useState from '@/composables/useState'
import useGeolocation from '@/composables/useGeolocation'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
const {
  screenRotation,
  appVersion,
  playerId,
  rtt,
  registrationStatus,
  rotateScreenLeft,
  rotateScreenRight,
  showDebugModal
} = useState()
const { running: geolocationRunning, dms, error: geolocationError, updates } = useGeolocation()

const currentStateCorner = ref(0)

const containerClasses = computed(() => {
  const corner = unref(currentStateCorner)
  const rotation = unref(screenRotation)
  const classes: string[] = ['fixed', 'm-4']
  switch (rotation) {
    case '0':
      switch (corner) {
        case 0:
          classes.push('bottom-0 right-0')
          break
        case 1:
          classes.push('bottom-0 left-0')
          break
        case 2:
          classes.push('top-0 left-0')
          break
        case 3:
          classes.push('top-0 right-0')
          break
      }
      break
    case '90':
      classes.push('rotate-90')
      switch (corner) {
        case 0:
          classes.push('bottom-0 right-0 origin-top-right translate-y-full')
          break
        case 1:
          classes.push('bottom-0 left-0 origin-bottom-right -translate-x-full')
          break
        case 2:
          classes.push('top-0 left-0 origin-bottom-left -translate-y-full')
          break
        case 3:
          classes.push('top-0 right-0 origin-top-left translate-x-full')
          break
      }
      break
    case '180':
      classes.push('rotate-180')
      switch (corner) {
        case 0:
          classes.push('top-0 left-0')
          break
        case 1:
          classes.push('top-0 right-0')
          break
        case 2:
          classes.push('bottom-0 right-0')
          break
        case 3:
          classes.push('bottom-0 left-0')
          break
      }
      break
    case '270':
      classes.push('-rotate-90')
      switch (corner) {
        case 0:
          classes.push('top-0 left-0 origin-top-right -translate-x-full ')
          break
        case 1:
          classes.push('top-0 right-0 origin-bottom-right -translate-y-full')
          break
        case 2:
          classes.push('bottom-0 right-0 origin-bottom-left translate-x-full')
          break
        case 3:
          classes.push('bottom-0 left-0 origin-top-left translate-y-full')
          break
      }
      break
  }
  return classes.join(' ')
})

const roundTripTime = computed(() => {
  const _rtt = unref(rtt)
  return _rtt === null ? 'n/a' : _rtt.toString() + 'ms'
})

const tick = ref(new Date().getTime())
const tickClock = setInterval(() => {
  tick.value = new Date().getTime()
}, 1000)

const currentTime = computed(() =>
  // @ts-ignore
  format(unref(tick), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })
)

let debugModalPositionInterval: any = null
debugModalPositionInterval = setInterval(() => {
  currentStateCorner.value = unref(currentStateCorner) > 2 ? 0 : unref(currentStateCorner) + 1
}, 60 * 1000)

const idx = ref(Capacitor.getPlatform() === 'web' ? -1 : -1)

const opening = ref(false)
const buttons = computed(() => [
  {
    icon: 'pi pi-undo rotate-180',
    fn: rotateScreenLeft
  },
  {
    icon: 'pi pi-refresh rotate-180',
    fn: rotateScreenRight
  },
  {
    icon: ['pi pi-cog', unref(opening) ? 'pi-spin' : ''],
    if: Capacitor.getPlatform() === 'android',
    fn: async () => {
      opening.value = true
      await NativeSettings.openAndroid({ option: AndroidSettings.Settings })
      opening.value = false
    }
  },
  {
    icon: 'pi pi-sign-out',
    if: Capacitor.getPlatform() === 'android',
    fn: () => {
      if (window.confirm('Quer sair da aplicação?')) App.exitApp()
    }
  }
])

let oldIdx = 0
const keydownEvtListener = (evt: KeyboardEvent) => {
  const { key } = evt
  if (unref(idx) < 0) {
    if (['ArrowUp', 'ArrowDown'].includes(key)) idx.value = oldIdx
    else if (key === 'ArrowLeft') idx.value = unref(buttons).length - 1
    else if (key === 'ArrowRight') idx.value = 0
    else if (key === 'Enter') showDebugModal.value = false
  } else if (key === 'Enter') {
    if (unref(idx) > -1) unref(buttons)[unref(idx)]?.fn()
    else showDebugModal.value = false
  } else if (unref(idx) > -1) {
    if (['ArrowUp', 'ArrowDown'].includes(key)) {
      oldIdx = unref(idx)
      idx.value = -1
    } else if (key === 'ArrowRight') idx.value = (unref(idx) + 1) % buttons.value.length
    else if (key === 'ArrowLeft')
      idx.value = unref(idx) - 1 < 0 ? unref(buttons).length - 1 : unref(idx) - 1
  }
}

if (Capacitor.getPlatform() === 'android') window.addEventListener('keydown', keydownEvtListener)

onBeforeUnmount(() => {
  if (Capacitor.getPlatform() === 'android')
    window.removeEventListener('keydown', keydownEvtListener)
  clearInterval(tickClock)
  clearInterval(debugModalPositionInterval)
})
</script>

<style scoped>
.btn-group {
  @apply w-full flex items-center justify-start gap-1 py-1 xl:gap-2 xl:py-2;
}
.btn {
  @apply text-xs w-5 h-5 xl:w-8 xl:h-8 xl:text-lg p-0.5 border rounded-md data-[active="true"]:bg-white/50 transition-colors;
}
</style>
