import { ref, unref } from 'vue'
import { Capacitor } from '@capacitor/core'
import { createGesture } from '@ionic/vue'
import useState from '@/composables/useState'
import { App } from '@capacitor/app'

const { toggleStagingMode, mouseMoving, isFullScreen, showDebugModal } = useState()

const clickCounter = ref(0)
let clickTimeout: ReturnType<typeof setTimeout> | null = null
let mouseInterval: ReturnType<typeof setTimeout> | null = null

const clickHandler = async () => {
  if (clickTimeout === null) clickTimeout = setInterval(() => {
    clickCounter.value = 0
    clickTimeout = null
  }, 10000)
  clickCounter.value++
  if (unref(clickCounter) === 30) {
    clickCounter.value = 0
    await toggleStagingMode()
  }
}

let lastOnStart = 0
const DOUBLE_CLICK_THRESHOLD = 500
const onStart = () => {
  const now = Date.now()

  if (Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD) {
    lastOnStart = 0
    showDebugModal.value = !unref(showDebugModal)
  } else {
    lastOnStart = now
  }
}

const keypressEventHandler = (evt: KeyboardEvent) => {
  evt.preventDefault()

  switch (evt.key) {
    case 'Enter':
    case 'ArrowUp':
    case 'ArrowDown':
    case 'ArrowLeft':
    case 'ArrowRight':
      if (Capacitor.getPlatform() === 'web') showDebugModal.value = !unref(showDebugModal)
      else {
        clickHandler()
        if (!unref(showDebugModal)) showDebugModal.value = true
      }
      break
  }
}

const mouseMoveEvtHandler = () => {
  mouseMoving.value = true
  if (typeof mouseInterval === 'number') clearInterval(mouseInterval)
  mouseInterval = setInterval(() => {
    mouseMoving.value = false
  }, 1000)
}

const fullScreenEventHandler = () => {
  isFullScreen.value = document.fullscreenElement !== null
}

const gesture = createGesture({
  gestureName: 'doubletap',
  el: window.document.body,
  threshold: 0,
  onStart
})

const setEventListeners = () => {
  gesture.enable()
  window.addEventListener('click', clickHandler)
  document.addEventListener('backbutton', () => {
    if (unref(showDebugModal)) showDebugModal.value = false
    else if (window.confirm('Quer sair da aplicação?')) App.exitApp()
  })

  window.addEventListener('keydown', keypressEventHandler)
  window.addEventListener('mousemove', mouseMoveEvtHandler)
  window.addEventListener('fullscreenchange', fullScreenEventHandler)
  // required to prevent 'confirm navigation' dialog on android if the user interacts with the screen
  // PS: also required in window.addEventListener('beforeunload'...
  // https://stackoverflow.com/questions/45710504/how-to-remove-confirm-navigation-pop-up-in-mobile-app
  window.onbeforeunload = null
}

const removeEventListeners = () => {
  gesture.destroy()
  if (Capacitor.getPlatform() !== 'web') window.removeEventListener('click', clickHandler)
  window.removeEventListener('keypress', keypressEventHandler)
  window.removeEventListener('mousemove', mouseMoveEvtHandler)
  window.removeEventListener('fullscreenchange', fullScreenEventHandler)
}

const useEventListeners = () => {
  return {
    setEventListeners,
    removeEventListeners
  }
}

export default useEventListeners
