<template>
  <div class="h-screen overflow-hidden bg-black select-none relative">
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="mode === 'staging'"
        class="fixed text-[7px] xl:text-xs px-0.5 bg-yellow-400 text-gray-900 rounded-bl-md z-50"
        :class="{
          'top-0 right-0': screenRotation === '0',
          'rotate-90 bottom-0 right-0 origin-top-right translate-y-full': screenRotation === '90',
          'rotate-180 bottom-0 left-0': screenRotation === '180',
          '-rotate-90 top-0 left-0 origin-top-right -translate-x-full': screenRotation === '270'
        }"
      >
        Staging
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <DownloadProgress
        v-if="!isNaN(downloadProgress)"
        :progress="downloadProgress"
        class="absolute text-xs font-black tabular-nums rounded-tl-md text-white/60 text-shadow shadow-black z-50 p-1"
        :class="{
          'bottom-0 right-0': screenRotation === '0',
          'rotate-90 bottom-0 left-0 origin-top-right translate-y-1/2': screenRotation === '90',
          'rotate-180 top-0 left-0': screenRotation === '180',
          '-rotate-90 top-0 right-0 origin-top-right -translate-x-full translate-y-1/2':
            screenRotation === '270'
        }"
      />
    </transition>

    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <current-state v-if="showDebugModal" />
    </transition>

    <transition
      enter-active-class="transition-opacity"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="mouseMoving && platform === 'web'"
        class="z-20 fixed top-0 right-0 flex items-start space-x-2 m-4"
      >
        <button @click="toggleFullScreen">
          <arrows-pointing-out
            v-if="!isFullScreen"
            class="w-10 h-10 rounded-md transition-colors hover:bg-gray-600"
          />
          <arrows-pointing-in
            v-else
            class="w-10 h-10 rounded-md transition-colors hover:bg-gray-600"
          />
        </button>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <waiting-screen v-if="registrationStatus === null || queue.length < 1">
        <component :is="component" />
      </waiting-screen>
      <v-buffer v-else :queue="queue" :rotation="screenRotation" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref, h, onBeforeUnmount } from 'vue'
import CurrentState from '@/components/CurrentState.vue'
import WaitingScreen from '@/components/WaitingScreen.vue'
import VBuffer from '@/components/VBuffer.vue'
import NetworkDisconnected from '@/components/NetworkDisconnected.vue'
import NotRegistered from '@/components/NotRegistered.vue'
import WaitingForQueue from '@/components/WaitingForQueue.vue'
import WaitingForRegistrationStatus from '@/components/WaitingForRegistrationStatus.vue'
import ArrowsPointingOut from '@/assets/svg/arrows-pointing-out.svg'
import ArrowsPointingIn from '@/assets/svg/arrows-pointing-in.svg'
import DownloadProgress from '@/components/DownloadProgress.vue'
import useScheduler from '@/composables/useScheduler'
import useNetworkStatus from '@/composables/useNetworkStatus'
import useState from '@/composables/useState'
import useAssets from '@/composables/useAssets'
import useEventListeners from '@/composables/useEventListeners'

const { setEventListeners, removeEventListeners } = useEventListeners()
const { networkStatus } = useNetworkStatus()
const { downloadProgress } = useAssets()
const { queue } = useScheduler()
const {
  registrationCode,
  registrationStatus,
  screenRotation,
  mouseMoving,
  platform,
  toggleFullScreen,
  isFullScreen,
  showDebugModal,
  mode
} = useState()

const component = computed(() => {
  const networkConnected = unref(networkStatus).connected
  const _registrationCode = unref(registrationCode)
  const registrationReceived =
    unref(registrationCode) !== null || unref(registrationStatus) !== null
  if (!networkConnected) return h(NetworkDisconnected)
  else if (!registrationReceived) return h(WaitingForRegistrationStatus)
  else if (typeof _registrationCode === 'string') return h(NotRegistered)
  else return h(WaitingForQueue)
})

setEventListeners()
onBeforeUnmount(() => removeEventListeners())
</script>
