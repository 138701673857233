<template>
  <div class="flex-1 grid grid-cols-2 items-center gap-8 xl:gap-12">
    <div
      class="flex flex-col justify-center items-center text-base xl:text-2xl leading-5 font-medium tracking-wider text-shadow shadow-black"
    >
      <span class="w-full text-center">Escaneie o código QR para registrar o seu dispositivo</span>
      <span class="w-full text-center">ou</span>
      <span class="w-full text-center">
        acesse o site
        <span class="text-[#54ffb1] underline">www.vontage.com.br</span>
        no seu computador e registre o dispositivo com o código abaixo:
      </span>
    </div>
    <div ref="qrcodeEl" class="w-full max-w-40 xl:max-w-64 mx-auto" />
    <div class="col-span-2 flex items-start justify-center h-full">
      <span
        class="text-3xl xl:text-6xl font-black border-2 border-[#54ffb1] rounded-full tracking-widest py-2 px-8"
      >
        {{ registrationCode }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, unref, watch } from 'vue'
import QRCode from 'qrcode'
import useState from '@/composables/useState'

const { registrationCode, appUrl } = useState()

const qrcodeEl = ref<null | HTMLElement>(null)
const qrcodeCanva = ref('')

watch(
  registrationCode,
  async (code) => {
    const url =
      code === null ? 'https://vontage.com.br' : unref(appUrl) + `/register-player/${code}`
    qrcodeCanva.value = await QRCode.toString(url, {
      color: { dark: '#000000ff', light: '#ffffffe0' }
    })
  },
  { immediate: true }
)
watch(
  [qrcodeEl, qrcodeCanva],
  ([qrcodeEl, qrcodeCanva]) => {
    if (qrcodeEl !== null) qrcodeEl.innerHTML = qrcodeCanva
  },
  { immediate: true }
)
</script>
