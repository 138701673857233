import { unref, watch } from 'vue'
import Axios, { type AxiosResponse } from 'axios'
import { attach as retryAttach } from 'retry-axios'
import useState from '@/composables/useState'

const { baseURL } = useState()

const instance = Axios.create({
  baseURL: unref(baseURL),
  timeout: 1200000
})

instance.interceptors.request.use((config) => {
  // config.timeout = 10000 // Wait for 10 seconds before timing out
  return config
})

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    throw error
  }
)

instance.defaults.raxConfig = {
  instance,
  retry: 8,
  backoffType: 'exponential'
}

retryAttach(instance)

watch(baseURL, (baseURL) => {
  instance.defaults.baseURL = baseURL
})

const useAxios = () => instance

export default useAxios
