import { ref, computed, unref, watch } from 'vue'
import debounce from 'lodash.debounce'
import useState from '@/composables/useState'
import useAxios from '@/composables/useAxios'
import useAuth from '@/composables/useAuth'
import type { IPlaylistAssignement } from '@vontage/types/v2/playlist'
import type { IQueueFrame } from '@/types'
import type { ICampaignTimeZone } from '@vontage/types/manual/campaign'

export const getDefaultTimeZone = (): ICampaignTimeZone => ({
  abbr: 'ESAST',
  offset: -3,
  text: '(UTC-03:00) Brasilia',
  utc: 'America/Sao_Paulo'
})

const { getBearerToken } = useAuth()
const { registrationStatus, playerId } = useState()
const axios = useAxios()

const queue = ref<IQueueFrame[]>([])

const playingFrame = ref<IQueueFrame | null>(null)
const assignements = ref<IPlaylistAssignement[]>([])

const updatePlaylistAssignements = debounce(async (): Promise<IPlaylistAssignement[]> => {
  const tenantId = unref(registrationStatus)?.tenant.id ?? null
  const quotaExcess = unref(registrationStatus)?.tenant.quotaExcess ?? true
  if (tenantId === null || quotaExcess === true) {
    assignements.value = []
    return []
  }
  const headers: Record<string, string> = {
    'x-player-id': unref(playerId),
    'x-tenant-id': tenantId,
    authorization: `Bearer ${await getBearerToken()}`
  }
  assignements.value = (await axios
    .get<IPlaylistAssignement[]>('/assignements', {
      headers
    })
    .then((res) => res.data)) as IPlaylistAssignement[]

  return unref(assignements)
}, 500)

const mapPlaylistAssignementToFrames = (assignement: IPlaylistAssignement): IQueueFrame[] => {
  const { urn: playlistURN, cron, playlist } = assignement

  const frames: IQueueFrame[] = []

  frames.push(
    ...playlist.frames.map((frame, i) => ({
      playlistURN,
      playlistSeqNo: i,
      cron,
      tz: unref(tz),
      ...frame
    }))
  )

  return frames
}

const interleave = (queue: IQueueFrame[][], mode: 'sequential' | 'interpolate' | 'interleave') => {
  let _queue: IQueueFrame[] = []
  if (mode === 'sequential') _queue = queue.flat()
  else {
    const maxLength = Math.max(...queue.map((o) => o.length))
    for (let i = 0; i < maxLength; i++) {
      for (let j = 0; j < queue.length; j++) {
        const nextFrame = queue?.[j]?.[mode === 'interleave' ? i : i % queue[j].length] ?? null
        if (nextFrame !== null) _queue.push(nextFrame)
      }
    }
  }

  return _queue.map((frame, playlistSeqNo) => ({ ...frame, playlistSeqNo }))
}

const updateQueue = debounce(async () => {
  const _queue = unref(assignements).map(mapPlaylistAssignementToFrames)
  queue.value = interleave(_queue, unref(playbackMode))
}, 1000)

const playbackMode = computed(
  () => unref(registrationStatus)?.campaign?.playlistPlayback ?? 'sequential'
)

const tz = computed(() => unref(registrationStatus)?.campaign?.timezone ?? getDefaultTimeZone())

watch(registrationStatus, () => updatePlaylistAssignements(), { immediate: true, deep: true })
watch([assignements, playbackMode, tz], () => updateQueue(), { deep: true })

const useScheduler = () => {
  return {
    updatePlaylistAssignements,
    queue: computed(() => unref(queue)),
    playingFrame: computed({
      get: () => unref(playingFrame),
      set: (value: IQueueFrame | null): void => {
        playingFrame.value = value
      }
    })
  }
}

export default useScheduler
