import { ref, unref, computed, watch } from 'vue'
import { distance, point } from '@turf/turf'
import { KalmanFilter } from 'kalman-filter'
import useState from '@/composables/useState'
import useWebsocket from '@/composables/useWebsocket'
import roundToDecimals from '@/helpers/roundToDecimals'
import type { IPositionMeasurement } from '@vontage/types/v2/geolocation'

// https://support.garmin.com/en-US/?faq=hRMBoCTy5a7HqVkxukhHd8
// 1.11m accuracy
const COORDINATE_DECIMAL_PLACES = 5

const updates = ref(0)
const watchId = ref<number | null>(null)
const error = ref<null | GeolocationPositionError>(null)
const currentMeasurement = ref<IPositionMeasurement | null>(null)
const lastReportedMeasurement = ref<IPositionMeasurement | null>(null)

const { connected } = useState()
const { sendPosition } = useWebsocket()

const toDegreesMinutesAndSeconds = (coordinate: number) => {
  const absolute = Math.abs(coordinate)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)
  const dms = `${degrees}°${minutes}'${seconds}''`
  return dms
}

const convertDMS = (coords: { lat: number; lng: number }) => {
  const { lat, lng } = coords
  const latitude = toDegreesMinutesAndSeconds(lat)
  const latitudeCardinal = lat >= 0 ? 'N' : 'S'

  const longitude = toDegreesMinutesAndSeconds(lng)
  const longitudeCardinal = lng >= 0 ? 'E' : 'W'
  const dms = `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`
  return dms
}

const kalman = new KalmanFilter({
  observation: 2
})
let previousCorrected: any = null

const positionUpdateHandler = (update: GeolocationPosition) => {
  if (unref(error) !== null) error.value = null

  const {
    timestamp,
    coords: { latitude: lat, longitude: long, accuracy }
  } = update

  const observation = [
    roundToDecimals(lat, COORDINATE_DECIMAL_PLACES),
    roundToDecimals(long, COORDINATE_DECIMAL_PLACES)
  ]

  const predicted = kalman.predict({ previousCorrected })
  const correctedState = kalman.correct({
    predicted,
    observation
  })
  const [latitude, longitude] = correctedState.mean.flat()
  previousCorrected = correctedState
  const measurement: IPositionMeasurement = {
    timestamp,
    latitude: roundToDecimals(latitude, COORDINATE_DECIMAL_PLACES),
    longitude: roundToDecimals(longitude, COORDINATE_DECIMAL_PLACES),
    accuracy: roundToDecimals(accuracy, 0)
  }

  const lastMeasurementSent = unref(lastReportedMeasurement)
  currentMeasurement.value = measurement
  const d =
    lastMeasurementSent === null
      ? null
      : distance(
          point([measurement.longitude, measurement.latitude]),
          point([lastMeasurementSent.longitude, lastMeasurementSent.latitude]),
          { units: 'meters' }
        )
  if (d === null || d > accuracy) lastReportedMeasurement.value = measurement
}

const errorHandler = (_error: GeolocationPositionError) => {
  error.value = _error
  console.error(_error)
}

const start = async () => {
  if (unref(watchId) !== null) return
  if (navigator.geolocation) {
    watchId.value = navigator.geolocation.watchPosition(positionUpdateHandler, errorHandler, {
      enableHighAccuracy: true
    })
  } else throw new Error('location services not available')
}

const stop = async () => {
  const id = unref(watchId)
  if (id === null) return
  await navigator.geolocation.clearWatch(id)
  watchId.value = null
}

const running = computed(() => unref(watchId) !== null && unref(error) === null)

const dms = computed(() => {
  const _position = unref(currentMeasurement)
  if (_position === null) return null
  const { latitude: lat, longitude: lng } = _position
  return convertDMS({ lat, lng })
})

watch(
  [connected, lastReportedMeasurement],
  ([connected, position]) => {
    if (connected && position !== null) {
      sendPosition(position)
      updates.value++
    }
  },
  { immediate: true }
)

const useGeolocation = () => {
  return {
    start,
    stop,
    running,
    dms,
    error,
    position: computed(() => unref(currentMeasurement)),
    updates: computed(() => unref(updates))
  }
}

export default useGeolocation
